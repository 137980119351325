<template>
<div class="share">
  <TopBack :title="$t('home.fxhtg')"></TopBack>

  <p>{{userInfo.inviteCode}}</p>
  <div class="uid">{{$t('share.uid')}}</div>
  <div class="qrCode">
    <qrcode-vue :value="userInfo.url" size="200"></qrcode-vue>
  </div>
  <van-button @click="copy()" class="copy">{{$t('share.zflj')}}</van-button>
  <div class="tips">{{$t('share.tips')}}</div>
</div>
</template>
<script setup>
import TopBack from './components/TopBack'
import {onBeforeMount, ref,getCurrentInstance} from 'vue'
import {Toast} from "vant";
import QrcodeVue from 'qrcode.vue'
import useClipboard from 'vue-clipboard3'
const { $t } = getCurrentInstance().proxy;
import {getUserInfo} from "../api";

const userInfo=ref({})
const getHttp=()=>{
  getUserInfo().then(res=>{
    res.data.url=res.data.url+'?inviteCode='+res.data.inviteCode
    userInfo.value=res.data
  })
}
onBeforeMount(()=>{
  getHttp()
})
const copy =async ()=>{
  const { toClipboard } = useClipboard()
  try {
    await toClipboard(userInfo.value.url)
    Toast($t('home.success'))
  } catch (e) {
    Toast($t('home.failed'))

  }
}
</script>
<style scoped lang="less">
.share {
  padding: 0px 18px 0 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  min-height: 100vh;
  .top-back{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    position: relative;
    .top-image{
      position: absolute;
      left: 0px;
      top: 18px;
      width: 16px;
      height: 15px;
    }
  }
  p{
    font-weight: bold;
    font-size: 31px;
    color: #FFFFFF;
    margin-bottom: 24px;
    margin-top: 62px;
  }
  .uid{
    font-weight: bold;
    font-size: 15px;
    color: #7E8D95;
    margin-bottom: 37px;
  }
  .qrCode{
    width: 208px;
    height: 208px;
    background: #FFFFFF;
    margin: 0 auto 65px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .copy{
    width: 322px;
    height: 56px;
    background: linear-gradient(90deg, #57F6CD, #AF65EE);
    box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
    border-radius: 28px;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    margin: 0 auto;
    border: none;
  }
  .tips{
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 24px;
  }
}
</style>
